import React from 'react';
import { css } from '@emotion/react';
import { mediaSp } from '../styles/media-query';
import styled from '@emotion/styled';

export default function TextField(props) {
  const { id, multiple, ...restProps } = props;

  if (multiple) {
    return (
      <div
        data-required={restProps.required}
        css={css`
          ${requiredStyle}
          display: inline-block;
          flex: 1;
          font-size: 1.6rem;
          ${mediaSp} {
            display: block;
            margin-bottom: 14px;
            flex: 0;
          }
        `}
      >
        <textarea
          id={id}
          {...restProps}
          css={css`
            width: 100%;
            border: none;
            border-radius: 6px;
            min-height: 120px;
            ${mediaSp} {
              min-height: auto;
            }
          `}
        ></textarea>
      </div>
    );
  }

  if (Array.isArray(id)) {
    return (
      <>
        {id.map((v, i) => {
          return (
            <div
              key={v}
              data-required={restProps.required}
              css={css`
                ${requiredStyle}
                display: inline-block;
                flex: 1;
                font-size: 1.6rem;
                ${mediaSp} {
                  display: block;
                  margin-bottom: 14px;
                  flex: 0;

                  ${i !== 0 && 'margin-top: 30px;'}
                }

                & + .required {
                  ${mediaSp} {
                    margin-top: 30px;
                  }
                }

                &:not(:last-of-type) {
                  margin-right: 30px;
                  ${mediaSp} {
                    margin-right: 0;
                  }
                }
              `}
            >
              <StyledInput
                id={v}
                {...Object.assign(
                  {},
                  ...Object.entries(restProps).map(([key, value]) => {
                    return Array.isArray(value)
                      ? { [key]: value[i] }
                      : { [key]: value };
                  })
                )}
              />
            </div>
          );
        })}
      </>
    );
  }

  return (
    <div
      data-required={restProps.required}
      css={css`
        ${requiredStyle}
        display: inline-block;
        flex: 1;
        font-size: 1.6rem;

        ${mediaSp} {
          display: block;
          margin-bottom: 14px;
          flex: 0;
        }
      `}
    >
      <StyledInput id={id} {...restProps} />
    </div>
  );
}

const requiredStyle = css`
  &[data-required='true'] {
    position: relative;
    &::before {
      color: #cc181e;
      content: '★';
      font-size: 8px;
      position: absolute;
      left: -15px;

      ${mediaSp} {
        top: -26px;
        left: 3px;
      }
    }
  }
`;

const StyledInput = styled.input`
  width: 100%;
  border-radius: 6px;
  border: none;
  &::placeholder {
    color: #bfbfbf;
  }
  &:not([type='checkbox']) {
    line-height: 40px;
    padding: 0 20px;
    ${mediaSp} {
      padding: 0 18px;
    }
  }
`;
