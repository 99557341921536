import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/seo';
import Inner from '../components/Inner';
import Title from '../components/Title';
import TextField from '../components/TextField';
import SelectField from '../components/SelectField';
import * as Styles from '../styles/pages/_contact.module.scss';
import { ContactFormContext } from '../context/contactFormContext.js';
import ContactPageWrapper from '../components/contact-page-wrapper';
import BackButton from '../components/back-button';
import { css } from '@emotion/react';
import { mediaSp } from '../styles/media-query';
import { SectionTitle } from '../components/SectionTitle';

export default function Contact() {
  const [submitting, setSubmitting] = useState(false);
  const {
    familyName,
    setFamilyName,
    givenName,
    setGivenName,
    familyNameKana,
    setFamilyNameKana,
    givenNameKana,
    setGivenNameKana,
    tel,
    setTel,
    email,
    setEmail,
    organization,
    setOrganization,
    organizationDepartment,
    setOrganizationDepartment,
    organizationTitle,
    setOrganizationTitle,
    reason,
    setReason,
    url,
    setUrl,
    body,
    setBody,
    isAgree,
    setIsAgree,
  } = useContext(ContactFormContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    navigate('/contact/confirmation/');
    setSubmitting(false);
  };

  const breadcrumb = [{ label: 'お問い合わせ', url: '/contact' }];

  return (
    <>
      <Seo
        url="/contact"
        title="お問い合わせ｜Uniforce（ユニフォース）株式会社"
        description="決算開示・IPO支援業務、Uniforce IPO準備クラウド・決算開示管理クラウドやその他ご相談などはこちらからお問い合わせください。"
        image="https://uniforce.co.jp/og-image.jpg"
        breadcrumbs={breadcrumb}
      />
      <Layout breadcrumb={breadcrumb}>
        <ContactPageWrapper>
          <Inner>
            <SectionTitle
              enText="Contact"
              titleText="お問い合わせ"
              css={css`
                position: relative;
                padding-top: 140px;
                z-index: 2;
                max-width: 1158px;
                margin: auto;
              `}
            />

            <div className={Styles.contact_page}>
              <p className={Styles.contact_page__note}>
                ご質問、ご相談は以下のフォームよりお送りください。
                <br className="pc-only" />
                内容確認後、担当から通常2〜4営業日以内にご連絡いたします。
              </p>
              <div className={Styles.contact_page__box__inner}>
                <form onSubmit={handleSubmit}>
                  <table className={Styles.contact_page__table}>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label}
                          htmlFor="family-name"
                        >
                          お名前
                        </label>
                      </th>
                      <td>
                        <TextField
                          id={['family-name', 'given-name']}
                          name={['family-name', 'given-name']}
                          value={[familyName, givenName]}
                          onChange={[
                            (e) => setFamilyName(e.target.value),
                            (e) => setGivenName(e.target.value),
                          ]}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label}
                          htmlFor="family-name-kana"
                        >
                          ふりがな
                        </label>
                      </th>
                      <td>
                        <TextField
                          id={['family-name-kana', 'given-name-kana']}
                          name={['family-name-kana', 'given-name-kana']}
                          value={[familyNameKana, givenNameKana]}
                          onChange={[
                            (e) => setFamilyNameKana(e.target.value),
                            (e) => setGivenNameKana(e.target.value),
                          ]}
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label}
                          htmlFor="tel"
                        >
                          電話番号
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="tel"
                          name="tel"
                          type="tel"
                          value={tel}
                          onChange={(e) => setTel(e.target.value)}
                          placeholder="03-0000-0000"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label}
                          htmlFor="email"
                        >
                          メールアドレス
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="email"
                          name="email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="sample@uniforce.co.jp"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label}
                          htmlFor="organization"
                        >
                          会社名
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="organization"
                          name="organization"
                          value={organization}
                          onChange={(e) => setOrganization(e.target.value)}
                          placeholder="Uniforce株式会社"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label_no_space}
                          htmlFor="organization-department"
                        >
                          部署（任意）
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="organization-department"
                          name="organization-department"
                          value={organizationDepartment}
                          onChange={(e) =>
                            setOrganizationDepartment(e.target.value)
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label_no_space}
                          htmlFor="organization-title"
                        >
                          役職（任意）
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="organization-title"
                          name="organization-title"
                          value={organizationTitle}
                          onChange={(e) => setOrganizationTitle(e.target.value)}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label}
                          htmlFor="url"
                        >
                          会社URL
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="url"
                          name="url"
                          type="url"
                          value={url}
                          onChange={(e) => setUrl(e.target.value)}
                          placeholder="http://www.〇〇〇〇〇〇〇〇"
                          required
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          htmlFor="reason"
                          css={css`
                            font-weight: normal;
                            margin-bottom: 0;
                            line-height: 40px;
                            font-size: 1.8rem;
                            color: #252a40;
                            ${mediaSp} {
                              font-size: 1.4rem;
                              margin-left: 15px;
                              line-height: 0;
                            }
                          `}
                        >
                          お問い合わせ内容
                        </label>
                      </th>
                      <td>
                        <SelectField
                          className={Styles.contact_page__select}
                          id="reason"
                          name="reason"
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          required
                          options={[
                            { value: 'サービスに関するお問い合わせ' },
                            { value: 'プレスリリースに関するお問い合わせ' },
                            { value: 'セキュリティに関するお問い合わせ' },
                            { value: '採用に関するお問い合わせ' },
                            { value: 'その他' },
                          ]}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <label
                          className={Styles.contact_page__label_no_space}
                          htmlFor="body"
                        >
                          内容詳細（任意）
                        </label>
                      </th>
                      <td>
                        <TextField
                          id="body"
                          name="body"
                          value={body}
                          onChange={(e) => setBody(e.target.value)}
                          multiple
                        />
                      </td>
                    </tr>
                  </table>
                  <div className={Styles.contact_page__checkbox}>
                    <input
                      id="privacy"
                      name="privacy"
                      type="checkbox"
                      required
                      checked={isAgree}
                      onChange={() => setIsAgree(!isAgree)}
                      css={css`
                        margin-bottom: 0;
                        position: relative;
                        &::before {
                          color: #cc181e;
                          content: '★';
                          font-size: 8px;
                          position: absolute;
                          left: -12px;
                          top: -5px;
                          ${mediaSp} {
                            top: -18px;
                            left: 0;
                          }
                        }
                      `}
                    />
                    <label
                      className={Styles.contact_page__checkbox__label}
                      htmlFor="privacy"
                      required
                    >
                      プライバシーポリシーに同意する。
                    </label>
                    <p>
                      Uniforce株式会社の
                      <Link to="/privacy/">プライバシーポリシー</Link>
                      をご確認の上、
                      <br className="pc-only" />
                      同意をお願いします。
                    </p>
                  </div>
                  <div
                    css={css`
                      width: 100%;
                      text-align: center;
                    `}
                  >
                    <button
                      type="submit"
                      disabled={submitting}
                      css={css`
                        display: block;
                        margin: auto;
                        width: 394px;
                        background: #252a40;
                        border-radius: 34px;
                        border: none;

                        position: relative;
                        overflow: hidden;
                        /*ボタンの形状*/
                        text-decoration: none;
                        display: inline-block;
                        border: 1px solid #555;
                        padding: 10px 30px;
                        text-align: center;
                        outline: none;
                        transition: ease 0.2s;

                        ${mediaSp} {
                          width: 242px;
                          height: 54px;
                          border-radius: 27px;
                        }

                        :before {
                          content: '';
                          position: absolute;
                          top: 0;
                          left: 0;
                          z-index: 2;
                          background: #fff;
                          width: 100%;
                          height: 100%;
                          transition: transform 0.6s
                            cubic-bezier(0.8, 0, 0.2, 1) 0s;
                          transform: scale(0, 1);
                          transform-origin: right top;
                        }

                        & > span {
                          position: relative;
                          z-index: 3;
                          color: #fff;
                        }

                        :hover {
                          span {
                            color: #252a40;
                          }

                          :before {
                            transform-origin: left top;
                            transform: scale(1, 1);
                          }
                        }
                      `}
                    >
                      <span
                        css={css`
                          color: #fff;
                          font-size: 2rem;
                          display: block;

                          ${mediaSp} {
                            font-size: 1.7rem;
                          }
                        `}
                      >
                        送信する
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className={Styles.button__wrapper}>
              <div className={Styles.contact_page__btn}>
                <BackButton path="/" />
              </div>
            </div>
          </Inner>
        </ContactPageWrapper>
      </Layout>
    </>
  );
}
