// extracted by mini-css-extract-plugin
export var button__wrapper = "_contact-module--button__wrapper--63b48";
export var contact_page = "_contact-module--contact_page--e1e35";
export var contact_page__box__inner = "_contact-module--contact_page__box__inner--f9e4c";
export var contact_page__btn = "_contact-module--contact_page__btn--c180d";
export var contact_page__button = "_contact-module--contact_page__button--23ca6";
export var contact_page__checkbox = "_contact-module--contact_page__checkbox--7bd90";
export var contact_page__checkbox__label = "_contact-module--contact_page__checkbox__label--3f535";
export var contact_page__checkbox_input = "_contact-module--contact_page__checkbox_input--9591c";
export var contact_page__label = "_contact-module--contact_page__label--be419";
export var contact_page__label_no_space = "_contact-module--contact_page__label_no_space--1b368";
export var contact_page__note = "_contact-module--contact_page__note--7b055";
export var contact_page__privacy = "_contact-module--contact_page__privacy--f7390";
export var contact_page__privacy_title = "_contact-module--contact_page__privacy_title--c80b8";
export var contact_page__select = "_contact-module--contact_page__select--3b8ae";
export var contact_page__table = "_contact-module--contact_page__table--d093d";