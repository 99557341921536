import React from 'react';
import { css } from '@emotion/react';
import { mediaSp } from '../styles/media-query';

export default function SelectField(props) {
  const { id, options, ...restProps } = props;

  return (
    <>
      <div
        data-required={restProps.required}
        css={css`
          display: block;
          flex: 1;
          color: #222;
          font-size: 1.6rem;

          &[data-required='true'] {
            position: relative;

            &::before {
              position: absolute;
              left: -15px;
              color: #cc181e;
              content: '★';
              font-size: 8px;
              ${mediaSp} {
                top: -25px;
                left: 3px;
              }
            }
          }
        `}
      >
        <select
          id={id}
          {...restProps}
          css={css`
            display: block;
            width: 100%;
            height: 40px;
            padding-left: 20px;
            border: none;
            appearance: none;
            background: #fff;
            border-radius: 6px;
            ${mediaSp} {
              padding-left: 18px;
              margin-bottom: 14px;
            }
          `}
        >
          <option value="none" disabled={restProps.required}>
            選択 <p>...</p>
          </option>
          {options.map((option, i) => (
            <option key={i} {...option}>
              {option.label || option.value || option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
